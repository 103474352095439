import React from 'react'
import { HR_Color } from '../../../../globals/GlobalColors'
import { Card, Flex, Typography } from 'antd'
import { Link } from 'react-router-dom'

const {Text}=Typography

function EvaluationItem(props:any) {
  return (
    <Link to={'/evaluation-form'+ '/' + props.data.doc.name + '/edit'} className='w-100'  >

            <Card hoverable bordered style={{ borderColor: HR_Color, borderWidth: 2, backgroundColor: HR_Color + '10', color: 'black200', width: '100%' }} >
                <Flex justify='space-around' gap={2}  align='center'  >
                    <Text className='titles' strong>{props.data.doc.name}</Text>
                    <Text className='titles' strong>{props.data.quarter}</Text>
                    <Text className='titles' strong>{props.data.employee_name}</Text>
                    <Text className='titles' strong>{props.data.doc.workflow_state}</Text>
                    <Text className='titles' strong>{props.data.owner}</Text>
                </Flex>
            </Card>
        </Link>  )
}

export default EvaluationItem