import axios from "axios";
import { BASE_URL } from "../Helper/urls";
import { message } from "antd";

export const MyAxios=axios.create({
    baseURL:BASE_URL,
    headers:{
        Authorization:localStorage.getItem('token'),
        'Content-Type': 'application/json',

    },
    
})

MyAxios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error.response && error.response.status === 401) {
        // Remove the token from localStorage or perform any other actions you need
        localStorage.removeItem('token');
        
      }
      message.error(`Error Occurred: ${error.message}`)
      return Promise.reject(error);
    }
  );