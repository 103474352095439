import { Card, Col, Divider, Flex, Row, Typography } from 'antd'
import React from 'react'
import { BASE_URL } from '../../../../Helper/urls'
import QRCode from 'qrcode.react';
import { EnvironmentFilled, MailFilled, PhoneFilled } from '@ant-design/icons';
const { Title, Text } = Typography
function BusinessCardData(props: any) {
    const leftColumnStyle: React.CSSProperties = {
        padding: '16px',

    };

    const rightColumnStyle: React.CSSProperties = {
        
    };

    const qrCodeStyle: React.CSSProperties = {
        // width: '100px',
        // height: '100px',
        margin: '0 auto'
    };
    function formatSaudiPhoneNumber(phoneNumber: string) {
        // Remove any non-numeric characters
        if (phoneNumber !== '' && phoneNumber != null) {
            phoneNumber = phoneNumber.replace(/\D/g, '');

            // Check if the phone number is the correct length
            if (phoneNumber.length !== 10) {
                return "";
            }
        
            // If the first digit is 0, remove it
            if (phoneNumber.charAt(0) === '0') {
                phoneNumber = phoneNumber.substring(1);
            }
            // Add the country code and split the number into its parts
            const formattedNumber = `+966 ${phoneNumber.substring(0, 2)} ${phoneNumber.substring(2, 5)} ${phoneNumber.substring(5)}`;

            return formattedNumber;
        } else {
            return ''

        }

    }
    const vCardData = `
BEGIN:VCARD
VERSION:3.0
FN:${props.data.full_arabic_name ?props.data.full_arabic_name + "|"+ props.data.employee_name:props.data.employee_name}
N:${props.data.employee_name};;;
TEL;TYPE=CELL:+966${props.data.cell_number}
Title:${props.data.job_title}
ORG:KABi
EMAIL:${props.data.user_id ?? ''}
END:VCARD
`.trim();
    return (
        <Row gutter={16} style={{ direction: 'ltr',marginTop:20,overflowX:'scroll' }}>
            <img src={BASE_URL+'files/pattern.png'} style={{position:'absolute',top:-150,right:-250,width:400,height:400,}} alt="" />
            <Col span={16}>
                <div style={leftColumnStyle}>
                    <Title style={{ direction: 'ltr' ,color:'blue'}} level={3}>{props.data.full_arabic_name??'' }</Title>
                    <Title style={{ direction: 'ltr',color:'blue' }} level={3}>{props.data.employee_name}</Title>

                    <Title  style={{ direction: 'ltr' }} level={5}>{props.data.job_title ?? ''}</Title>
                    <br />
                    <Flex style={{ direction: 'ltr' }} gap={"small"}> 
                    <MailFilled style={{color:'blue'}} />       
                        <Text  style={{ direction: 'ltr' }} >{props.data.user_id ?? ''}</Text>
                    </Flex>
                    <Flex style={{ direction: 'ltr' }} gap={"small"} >   
                    <PhoneFilled style={{color:'green'}} />      
                        <Text style={{ direction: 'ltr' }} >{formatSaudiPhoneNumber(props.data.cell_number) ?? ''}</Text>
                    </Flex>

                    <Flex style={{ direction: 'ltr' }} gap={"small"}>  
                    <EnvironmentFilled style={{color:'blue'}} />     
                         <Text style={{ direction: 'ltr' }}>Hittin, Lumiere Center,1st Floor, Riyadh, Saudi Arabia</Text>
                         </Flex>

                </div>
            </Col>
            <Col span={8} >
                <div style={rightColumnStyle}>
                    {props.data.cell_number ? <QRCode className='w-lg-100  h-lg-100  h-75  my-3'   level="H" style={{width:'90%'}}  value={vCardData} /> : ''}

                </div>
            </Col>
            <Flex style={{ direction: 'ltr' }} gap={'small'} align='center' className='w-lg-75 w-100 mx-3' justify='space-between'><>
                <img style={{ width: 20, height: 20 }} src="/image/app-logo.png" alt="" />
                <div >KSA</div>
                <Divider orientation='center' type='vertical' style={{ backgroundColor: 'blue' }}></Divider>
                <div>UAE</div>
                <Divider orientation='center' type='vertical' style={{ backgroundColor: 'green' }}></Divider>

                <div>KWT</div>
                <Divider orientation='center' type='vertical' style={{ backgroundColor: 'blue' }}></Divider>

                <div>EGY</div>
                <Divider orientation='center' type='vertical' style={{ backgroundColor: 'green' }}></Divider>

                <div>UK</div>
                <Divider orientation='center' type='vertical' style={{ backgroundColor: 'blue' }}></Divider>

                <div>USA</div>
            </></Flex>
        </Row>
    )
}

export default BusinessCardData