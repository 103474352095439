import React, { useContext, useEffect, useState } from 'react'
import MainLayout from '../../../layouts/main/MainLayout'
import { Link } from 'react-router-dom'
import { FloatButton, List, Radio } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { CustomContext } from '../../../App'
import { MyAxios } from '../../../globals/MyAxios'
import PaymentHeader from './component/PaymentHeader'
import PaymentItem from './component/PaymentItem'

export default function PaymentRequestList() {
    const [loading,setLoading]=useState(true)
    const [sortFilter,setSortFilter]=useState<keyof typeof data>('my')
    const [data,setData]=useState({'my':[],'all':[]})
    const{locale,direction}=useContext(CustomContext)
  
    useEffect(()=>{
      const feachData=async()=>{
      const res =await MyAxios.get('api/method/kabi.api.get_lists.get_list',{params:{doctype:'Payment Request KABi',allow_all:'Purchase Manager'}})
      setData(res.data.message)
      setLoading(false)
      }
      feachData()
    },[])
  
      return (
      <MainLayout title='Payment Request' loading={loading}>

        {data['all'].length==0?<></>:
              <Radio.Group
              value={sortFilter}
              onChange={(e) => {
                setSortFilter(e.target.value);
              }}
              ><Radio.Button className='titles' value="my">{locale['your_requests'][direction]}</Radio.Button>
              <Radio.Button className='titles' value="all">{locale['all_requests'][direction]}</Radio.Button>
              
              
              
            </Radio.Group>}
            <br />
  
       <List
       header={<PaymentHeader/>}
       dataSource={data[sortFilter] ||[]}
       pagination={{pageSize:5,responsive:true,defaultCurrent:1}}
       renderItem={(item:any) => (
        <>
        <PaymentItem data={item} />
        <br />
        </>
      )}
           >
  
       </List>
  
      </MainLayout>
    )
}
