import React, { useContext } from 'react'
import MainLayout from '../../layouts/main/MainLayout'
import { useParams } from 'react-router-dom'
import GeneralForm from './Forms/GeneralForm'
import LeaveForm from './Forms/LeaveForm'
import RemoteWorkForm from './Forms/RemoteWorkForm'
import ExpenseClaimForm from './Forms/ExpenseClaimForm'
import PurchaseForm from './Forms/PurchaseForm'
import PaymentForm from './Forms/PaymentForm'
import { BranchesOutlined, CommentOutlined } from '@ant-design/icons'
import Comments from './Component/Comments'
import WorkflowUI from './Component/WorkflowUI'
import { Card, Tabs, TabsProps } from 'antd'
import { CustomContext } from '../../App'
import BusinessTripForm from './Forms/BusinessTripForm'

export default function MyForm(props:any) {
    const {doctype,name}=useParams()
    const {locale,direction}=useContext(CustomContext)

    const items: TabsProps['items'] = [
        {
          key: '1',
          label:locale['workflow'][direction],
          icon:<BranchesOutlined />,
          children: <WorkflowUI doctype={doctype} name={name}/>,
        },
        {
          key: '2',
          label: locale['comments'][direction],
          icon:<CommentOutlined />,
          children: <Comments doctype={doctype} name={name}/>,
        },
       
      ];
    const get_Form=(edit:boolean)=>{
        if(doctype=='general-request'){
            return <GeneralForm  edit={edit}/>
        }else if(doctype=='leave-application'){
            return <LeaveForm edit={edit}/>
        }else if(doctype=='remote-work'){
            return <RemoteWorkForm edit={edit}/>
        }else if(doctype=='business-trip'){
            return <BusinessTripForm/>
        }else if(doctype=='expense-claim'){
            return <ExpenseClaimForm edit={edit}/>
        }else if(doctype=='purchase-order'){
            return <PurchaseForm edit={edit}/>
        }else if(doctype=='payment-request'){
            return <PaymentForm edit={edit}/>
        }
    }
  return (
    <MainLayout title={doctype?.replaceAll('-','_')}>
        <Card bordered={name!==undefined?true:false}>{get_Form(props.edit)}</Card>
        <br />
       {props.edit?<Card>
        <Tabs defaultActiveKey="1" items={items}/>
        </Card>:<></>} 
    </MainLayout>
  )
}
